'use client'

import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { Suspense } from 'react'

import { IS_TESTING } from '../constants/is-testing'
import { usePosthog } from '../hooks/usePosthog'

const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '<NEXT_PUBLIC_POSTHOG_KEY> is not defined'
const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST ?? 'https://us.i.posthog.com'

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    capture_pageleave: true,
    debug: process.env.NODE_ENV === 'development' || IS_TESTING,
    ip: false,
    person_profiles: 'always',
  })
}

export const PostHogProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <PHProvider client={posthog}>
      <Suspense>
        <PostHogHelper />
      </Suspense>
      {children}
    </PHProvider>
  )
}

const PostHogHelper = () => {
  usePosthog()
  return null
}
