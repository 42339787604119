/* eslint-disable max-lines */
// source: https://github.com/Uniswap/interface/blob/main/src/utils/formatNumbers.ts

import { formatUnits, parseUnits } from 'viem'

import { TOKEN_DECIMALS } from '../constants/Tokens'
import { Nullish, TokenType } from '../types'

import { trySanitize } from './try-sanitize'
import { regexNumberCheck } from './validation'

const LOCALE = 'en-US'

const COMMAS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 18,
  minimumFractionDigits: 0,
  notation: 'standard',
})

const MAX_FOUR_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 4,
  notation: 'compact',
})

const MAX_THREE_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 3,
  notation: 'compact',
})

const THREE_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
  notation: 'compact',
})

const TWO_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: 'compact',
})

const ONE_DECIMAL = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  notation: 'compact',
})

const MAX_TWO_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  notation: 'compact',
})

const NO_DECIMALS_COMPACT = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  notation: 'compact',
})

const NO_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 0,
  notation: 'standard',
})

const MAX_TWO_DECIMALS_USD_COMPACT = new Intl.NumberFormat(LOCALE, {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  notation: 'compact',
  style: 'currency',
})

const TWO_DECIMALS_USD = new Intl.NumberFormat(LOCALE, {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: 'standard',
  style: 'currency',
})

const FOUR_DECIMALS_USD = new Intl.NumberFormat(LOCALE, {
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
  style: 'currency',
})

const SHORTHAND_TWO_DECIMALS = new Intl.NumberFormat(LOCALE, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  notation: 'compact',
})

const SHORTHAND_USD_ONE_TO_TWO_DECIMAL = new Intl.NumberFormat(LOCALE, {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 1,
  notation: 'compact',
  style: 'currency',
})

const ONE_TO_FOUR_SIG_FIGS_STANDARD = new Intl.NumberFormat(LOCALE, {
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 1,
  notation: 'standard',
})

const ONE_TO_FOUR_SIG_FIGS_COMPACT = new Intl.NumberFormat(LOCALE, {
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 1,
  notation: 'compact',
})

const TWO_SIG_FIGS_NO_COMMAS = new Intl.NumberFormat(LOCALE, {
  maximumSignificantDigits: 2,
  minimumSignificantDigits: 1,
  notation: 'compact',
  useGrouping: false,
})

type Format = Intl.NumberFormat | string

// each rule must contain either an `upperBound` or an `exact` value.
// upperBound => number will use that formatter as long as it is < upperBound
// exact => number will use that formatter if it is === exact
type FormatterRule =
  | { upperBound?: undefined; exact: number; formatter: Format }
  | { upperBound: number; exact?: undefined; formatter: Format }

// these formatter objects dictate which formatter rule to use based on the interval that
// the number falls into. for example, based on the rule set below, if your number
// falls between 1 and 1e6, you'd use TWO_DECIMALS as the formatter.
const tokenBalanceFormatter: FormatterRule[] = [
  { exact: 0, formatter: '0' },
  { formatter: '<0.001', upperBound: 0.001 },
  { formatter: MAX_THREE_DECIMALS, upperBound: 1 },
  { formatter: MAX_TWO_DECIMALS, upperBound: 1e6 },
  { formatter: SHORTHAND_TWO_DECIMALS, upperBound: 1e15 },
  { formatter: '>999T', upperBound: Infinity },
]

const swapTradeAmountFormatter: FormatterRule[] = [
  { exact: 0, formatter: '0' },
  { formatter: TWO_SIG_FIGS_NO_COMMAS, upperBound: 1 },
  { formatter: ONE_TO_FOUR_SIG_FIGS_STANDARD, upperBound: 10_000 },
  { formatter: ONE_TO_FOUR_SIG_FIGS_COMPACT, upperBound: Infinity },
]

const fiatTokenAmountFormatter: FormatterRule[] = [
  { exact: 0, formatter: '$0' },
  { formatter: SHORTHAND_USD_ONE_TO_TWO_DECIMAL, upperBound: -0.01 },
  { formatter: '<$0.01', upperBound: 0.01 },
  { formatter: TWO_DECIMALS_USD, upperBound: 10000 },
  { formatter: SHORTHAND_USD_ONE_TO_TWO_DECIMAL, upperBound: Infinity },
]

const inputFormatter: FormatterRule[] = [{ formatter: COMMAS, upperBound: Infinity }]
const maxTwoDecimalsUSDCompactFormatter: FormatterRule[] = [
  { formatter: MAX_TWO_DECIMALS_USD_COMPACT, upperBound: Infinity },
]
const threeDecimalsFormatter: FormatterRule[] = [{ formatter: THREE_DECIMALS, upperBound: Infinity }]
const twoDecimalFormatter: FormatterRule[] = [{ formatter: TWO_DECIMALS, upperBound: Infinity }]
const maxFourDecimalsFormatter: FormatterRule[] = [{ formatter: MAX_FOUR_DECIMALS, upperBound: Infinity }]
const oneDecimalFormatter: FormatterRule[] = [{ formatter: ONE_DECIMAL, upperBound: Infinity }]
const noDecimalCompactFormatter: FormatterRule[] = [{ formatter: NO_DECIMALS_COMPACT, upperBound: Infinity }]
const noDecimalFormatter: FormatterRule[] = [{ formatter: NO_DECIMALS, upperBound: Infinity }]
const stablecoinPriceFormatter: FormatterRule[] = [{ formatter: FOUR_DECIMALS_USD, upperBound: Infinity }]

export enum NumberType {
  // used for token quantities in non-transaction contexts (e.g. portfolio balances)
  TokenBalance = 'token-balance',

  // this formatter is only used for displaying the swap trade output amount
  // in the text input boxes. Output amounts on review screen should use the above TokenTx formatter
  SwapTradeAmount = 'swap-trade-amount',

  // fiat values for market cap, TVL, volume in the Token Details screen
  FiatTokenAmt = 'fiat-token-amt',

  // for input formatting
  Input = 'input',
  ThreeDecimals = 'three-decimal',
  TwoDecimals = 'two-decimal',
  NoDecimalsCompact = 'no-decimal-compact',
  NoDecimals = 'no-decimal',
  StablecoinPrice = 'stablecoin-price',
  OneDecimal = 'one-decimal',
  MaxFourDecimals = 'max-four-decimals',
  MaxTwoDecimalsUSDCompact = 'max-two-decimals-usd-compact',
}

const TYPE_TO_FORMATTER_RULES = {
  [NumberType.TokenBalance]: tokenBalanceFormatter,
  [NumberType.SwapTradeAmount]: swapTradeAmountFormatter,
  [NumberType.FiatTokenAmt]: fiatTokenAmountFormatter,
  [NumberType.Input]: inputFormatter,
  [NumberType.ThreeDecimals]: threeDecimalsFormatter,
  [NumberType.TwoDecimals]: twoDecimalFormatter,
  [NumberType.NoDecimalsCompact]: noDecimalCompactFormatter,
  [NumberType.StablecoinPrice]: stablecoinPriceFormatter,
  [NumberType.OneDecimal]: oneDecimalFormatter,
  [NumberType.MaxFourDecimals]: maxFourDecimalsFormatter,
  [NumberType.NoDecimals]: noDecimalFormatter,
  [NumberType.MaxTwoDecimalsUSDCompact]: maxTwoDecimalsUSDCompactFormatter,
}

export function getFormatterRule(input: number, type: NumberType): Format {
  const rules = TYPE_TO_FORMATTER_RULES[type]

  for (const rule of rules) {
    if (
      (rule.exact !== undefined && input === rule.exact) ||
      (rule.upperBound !== undefined && input < rule.upperBound)
    ) {
      return rule.formatter
    }
  }

  throw new Error(`formatter for type ${type} not configured correctly`)
}

// Overload signatures
export function formatNumber(input: Nullish<number>, type: NumberType, placeholder: string): string
export function formatNumber(input: Nullish<number>, type?: NumberType, placeholder?: undefined): string | undefined

// Implementation
export function formatNumber(
  input: Nullish<number>,
  type: NumberType = NumberType.TokenBalance,
  placeholder?: string,
): string | undefined {
  if (typeof input !== 'number' || Number.isNaN(input) || !Number.isFinite(input)) {
    return placeholder
  }

  const formatter = getFormatterRule(input, type)
  if (typeof formatter === 'string') return formatter
  return formatter.format(input)
}

// Overload signatures
export function formatBigInt(
  input: Nullish<bigint>,
  token: TokenType,
  type: NumberType | 'raw',
  placeholder: string,
): string
export function formatBigInt(
  input: Nullish<bigint>,
  token: TokenType,
  type: NumberType | 'raw',
  placeholder?: undefined,
): string | undefined

export function formatBigInt(
  input: Nullish<bigint>,
  token: TokenType,
  type: NumberType | 'raw' = NumberType.TokenBalance,
  placeholder?: string,
): string | undefined {
  if (input === null || input === undefined || typeof input !== 'bigint') {
    return placeholder
  }

  const decimals = TOKEN_DECIMALS[token] ?? 18

  const adjustedInput = formatUnits(input, decimals)
  if (type === 'raw') return adjustedInput
  return formatStringifiedNum(adjustedInput, type, placeholder)
}

export const formatTokenUnits = (input: Nullish<bigint>, token: TokenType, placeholder = '-') => {
  if (input === null || input === undefined) {
    return placeholder
  }

  return formatUnits(input, TOKEN_DECIMALS[token] ?? 18)
}

export function formatStringifiedNum(
  amount: Nullish<string>,
  type: NumberType = NumberType.TokenBalance,
  placeholder?: string,
): string | undefined {
  // @ts-expect-error - it's expected due to overload signatures
  return formatNumber(amount ? parseFloat(amount) : undefined, type, placeholder)
}

export const formatStringifiedNumWithTrailingDecimal = (
  amount: Nullish<string>,
  type: NumberType = NumberType.Input,
  placeholder?: string,
) => {
  const trailingDecimal = amount?.endsWith('.') ? '.' : ''
  return formatStringifiedNum(amount, type, placeholder) + trailingDecimal
}

export const trimLeadingZero = (num: string) => {
  if (num === '.') return '0.'
  const [integerPart] = num.split('.')
  if (integerPart.length > 1 && integerPart.startsWith('0')) return num.slice(1)
  return num
}

export const removeCommas = (input: string) => {
  return input.replace(/,/g, '')
}

export const removeTrailingDecimal = (input: string) => {
  return input.endsWith('.') ? input.slice(0, input.length - 1) : input
}

export const sanitizeNumInput = (input: string) => {
  return removeTrailingDecimal(trimLeadingZero(removeCommas(trySanitize(input))))
}

export const addSuffix = (num: string, suffix: string) => {
  return `${num} ${suffix}`
}

export const addCommas = (num: string): string => {
  const hasDecimal = num.includes('.')
  const parts = num.split('.')
  const formattedStringifiedNum = formatStringifiedNum(parts[0], NumberType.Input, '')
  if (!formattedStringifiedNum) return ''
  parts[0] = formattedStringifiedNum
  let output = parts[0]
  if (hasDecimal) output += '.'
  if (parts[1]) output += parts[1]
  return output
}

export const parseUnitsOrZero = (input: string, tokenOrDecimals: TokenType | number) => {
  const sanitizedInput = sanitizeNumInput(input)
  let decimals: number
  if (typeof tokenOrDecimals === 'number') decimals = tokenOrDecimals
  else decimals = TOKEN_DECIMALS[tokenOrDecimals] ?? 18
  return parseUnits(
    (sanitizedInput.length && regexNumberCheck(sanitizedInput, 18) ? sanitizedInput : '0') as `${number}`,
    decimals,
  )
}

export const parseWei = (input: string) => parseUnits(input, 0)

export const formatDecimals = (num: number, decimals = 2) => {
  return num.toFixed(decimals)
}

export const truncateTokenBalance = (num: Nullish<string>) => {
  if (!num) return num
  const parsedBal = parseFloat(num)
  const [integerPart, decimalPart] = num.split('.')
  if (!parsedBal || parsedBal < 0.001 || !decimalPart || !decimalPart.length) return num

  if (parsedBal < 1) {
    // truncate to 3 decimal places
    return integerPart + '.' + decimalPart.slice(0, 3)
  }

  // truncate to 2 decimal places
  return integerPart + '.' + decimalPart.slice(0, 2)
}

export const roundToMillion = (num: number) => {
  if (num >= 1_000_000) {
    return formatNumber(num, NumberType.MaxTwoDecimalsUSDCompact)
  }

  const inMillions = num / 1_000_000
  const rounded = Math.round(inMillions * 100) / 100

  // If less than 0.005M (5k), return 0
  if (rounded < 0.005) {
    return formatNumber(0, NumberType.MaxTwoDecimalsUSDCompact)
  }

  return `$${rounded.toFixed(2)}M`
}
