import { defineChain } from 'viem'

const sourceId = 1 // mainnet

export const kinto = defineChain({
  blockExplorers: {
    default: {
      name: 'Kintoscan',
      url: 'https://kintoscan.io/',
    },
  },
  id: 7887,
  name: 'Kinto',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://rpc.kinto-rpc.com'] },
  },
  sourceId,
})
