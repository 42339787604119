'use client'

import { TooltipContentProps, TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import { ResponsiveSide } from '../types'

import { baiJamjuree } from './AppSetup'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'

interface TooltipProps extends Omit<TooltipContentProps, 'content' | 'side'> {
  width?: string
  padding?: string
  textSize?: string
  variant?: 'default' | 'dark'
  content: React.ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left' | ResponsiveSide
}

export const EthenaTooltip: React.FC<TooltipProps> = ({
  children,
  content,
  padding = 'p-2',
  side = 'top',
  textSize = 'text-xs',
  variant = 'default',
  width = 'w-[200px]',
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const { className, ...rest } = props

  const [currentSide, setCurrentSide] = useState<'top' | 'right' | 'bottom' | 'left'>(
    typeof side === 'object' ? side.base : side,
  )

  // Add effect to handle responsive side changes
  useEffect(() => {
    const handleResize = () => {
      if (typeof side === 'object') {
        const isSmallScreen = window.innerWidth <= 640 // Tailwind's sm breakpoint
        setCurrentSide(isSmallScreen && side.sm ? side.sm : side.base)
      }
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [side])

  return (
    <TooltipProvider delayDuration={400}>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger
          onClick={() => {
            setOpen(!open)
          }}
          onFocus={() => {
            setTimeout(() => setOpen(true), 0)
          }}
          onBlur={() => {
            setOpen(false)
          }}
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            side={currentSide}
            className={clsx(
              'z-[9999]',
              baiJamjuree.className,
              width,
              padding,
              textSize,
              className,
              variant === 'default' ? 'tooltip' : 'tooltip-dark',
            )}
            {...rest}
          >
            {content}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}
