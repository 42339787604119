'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'

interface NavItemProps {
  title: string
  route: string
  classes?: string
  padding?: string
  isActiveOverride?: boolean
}

export function NavItem({ classes, isActiveOverride, padding = 'py-6 px-3 lg:px-4', route, title }: NavItemProps) {
  const path = usePathname()
  const isActive = path === route

  return (
    <Link
      href={route}
      className={clsx(
        'text-sm',
        isActive || isActiveOverride ? 'text-cblue-400 selected-nav-item' : 'hover:text-cblue-100',
        classes,
        padding,
      )}
      target={route.startsWith('/') ? undefined : '_blank'}
    >
      {title}
    </Link>
  )
}
