'use client'

import Placeholder from './Placeholder'

export interface FooterStatProps {
  stat?: string
  title: string
}

export const FooterStat = ({ stat, title }: FooterStatProps) => {
  return (
    <div className="flex gap-1 text-neutral-400 text-xs sm:hidden">
      <span>{title + ':'}</span>
      <Placeholder isLoading={!stat} classes="w-[42px] h-4">
        {stat}
      </Placeholder>
    </div>
  )
}
