'use client'

import { usePostHog } from 'posthog-js/react'
import React, { useEffect, useState } from 'react'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import { IoCloseOutline } from 'react-icons/io5'
import { PiBugBeetleBold } from 'react-icons/pi'

import { BUG_REPORT_SURVEY_ID } from '../constants/bug-report'
import { trySanitize } from '../utils/try-sanitize'

import { FillButton } from './FillButton'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { WarningBox, WarningBoxVariant } from './WarningBox'

const COOLDOWN_PERIOD = 10 * 1000

export const BugReportButton = () => {
  const posthog = usePostHog()
  const [isOpen, setIsOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [lastSubmissionTime, setLastSubmissionTime] = useState(0)
  const [cooldownRemaining, setCooldownRemaining] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = Math.max(0, COOLDOWN_PERIOD - (Date.now() - lastSubmissionTime))
      setCooldownRemaining(remaining)
    }, 1000)

    return () => clearInterval(timer)
  }, [lastSubmissionTime])

  const handleFeedbackSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (cooldownRemaining > 0) return

    posthog.capture('survey sent', {
      $survey_id: BUG_REPORT_SURVEY_ID,
      $survey_response: trySanitize(feedback),
    })

    setLastSubmissionTime(Date.now())
    setIsSubmitted(true)
    setFeedback('')
  }

  const handleDismiss = () => {
    if (!cooldownRemaining) {
      posthog.capture('survey dismissed', {
        $survey_id: BUG_REPORT_SURVEY_ID,
      })
    }

    setIsOpen(false)
    setIsSubmitted(false)
  }

  const handleOpenChange = (open: boolean) => {
    if (open) {
      posthog.capture('survey opened', { $survey_id: BUG_REPORT_SURVEY_ID })
    } else if (!isSubmitted) {
      posthog.capture('survey dismissed', { $survey_id: BUG_REPORT_SURVEY_ID })
    } else {
      setTimeout(() => setIsSubmitted(false), 1000)
    }

    setIsOpen(open)
  }

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger onClick={() => handleOpenChange(true)} title="Report a Bug">
        <PiBugBeetleBold className="text-[#808080] h-5 w-5" />
      </PopoverTrigger>
      <PopoverContent
        side="top"
        align="center"
        sideOffset={20}
        alignOffset={20}
        className="!w-80 p-4 rounded-lg border border-[#2E323B] bg-black bg-opacity-90 backdrop-blur-[4.5px] z-50"
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">Report a Bug</h3>
          <button className="text-neutral-600 hover:text-neutral-400" onClick={handleDismiss}>
            <IoCloseOutline size={24} />
          </button>
        </div>
        {isSubmitted ? (
          <div className="flex flex-col items-center justify-center h-40">
            <BiCheckCircle className="text-4xl text-green-500 mb-2" />
            <p className="text-white text-center">Thank you for your feedback!</p>
          </div>
        ) : (
          <form onSubmit={handleFeedbackSubmit}>
            <textarea
              name="feedback"
              placeholder="Describe the bug..."
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              className="w-full h-24 p-2 mb-4 bg-black text-white rounded-md resize-none border border-[#1f2738]"
              required
            />
            <WarningBox variant={WarningBoxVariant.DARK} classes="mb-4">
              <BiErrorCircle className="text-xl text-primary-light" />
              <p className="text-xs">
                {cooldownRemaining > 0
                  ? `You can submit again in ${Math.ceil(cooldownRemaining / 1000)} seconds.`
                  : 'Your feedback helps us improve. Thank you!'}
              </p>
            </WarningBox>
            <FillButton
              text="Submit"
              type="submit"
              classes="w-full"
              variant="primary"
              fontSize="text-sm"
              padding="p-2"
              rounding="rounded-lg"
              disabled={cooldownRemaining > 0}
            />
          </form>
        )}
      </PopoverContent>
    </Popover>
  )
}
