'use client'

import Image from 'next/image'

import clsx from 'clsx'
import { useMemo } from 'react'

import { Nullish } from '../types'

import { EthenaTooltip } from './EthenaTooltip'
import Placeholder from './Placeholder'

interface LandingStatProps {
  imgSrc: string
  label: string
  value: Nullish<string>
  classes?: string
  imgClasses?: string
  tooltip?: string | React.ReactNode
}

export const StatWithIcon = ({ classes, imgClasses, imgSrc, label, tooltip, value }: LandingStatProps) => {
  const content = useMemo(
    () => (
      <div
        className={clsx(
          'flex flex-col items-start justify-center px-2 md:px-2.5 2xl:px-4 w-fit pt-[3px] gap-0.5',
          classes,
        )}
      >
        <div className="flex items-center gap-1">
          <Image
            src={imgSrc}
            width={0}
            height={0}
            style={{ height: '16px', width: '12px' }}
            alt={label}
            className={imgClasses}
          />
          <span className="text-xs text-white font-medium leading-none">{label}</span>
          {tooltip && (
            <EthenaTooltip padding="p-3" content={tooltip} width="w-[200px] sm:w-[250px]">
              <Image src="/shared/info-round.svg" alt="Info icon" width={10} height={10} className="opacity-70" />
            </EthenaTooltip>
          )}
        </div>
        <Placeholder isLoading={!value} classes="w-[32px] mt-1 h-3.5">
          <span className="text-sm font-bold text-cblue-400">{value}</span>
        </Placeholder>
      </div>
    ),
    [classes, imgClasses, imgSrc, label, value, tooltip],
  )

  return content
}
