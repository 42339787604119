'use client'

import { Bai_Jamjuree } from 'next/font/google'
import Image from 'next/image'

import { ReactNode } from 'react'
import toast, { ToastBar, Toaster, ToastType } from 'react-hot-toast'
import { BsCheck2Circle } from 'react-icons/bs'
import { VscClose } from 'react-icons/vsc'
import { SWRConfig } from 'swr'

import { PostHogProvider } from '../providers/posthog'
import { handleErrorAndParseRes } from '../utils/handleErrorAndParseRes'

export interface AppSetupProps {
  children: React.ReactNode
}

export const baiJamjuree = Bai_Jamjuree({ subsets: ['latin'], weight: ['200', '300', '400', '500', '600', '700'] })

const TOAST_ICON_MAP: Partial<Record<ToastType, ReactNode>> = {
  error: <Image src="/shared/error.svg" alt="error" width={19.3} height={19.3} />,
  success: <BsCheck2Circle className="text-green-500 text-xl" />,
}

export const AppSetup = ({ children }: AppSetupProps) => {
  return (
    <PostHogProvider>
      <SWRConfig
        value={{
          fetcher: (resource, init) => fetch(resource, init).then(res => handleErrorAndParseRes(res)),
        }}
      >
        {children}
      </SWRConfig>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 10000,
          style: {
            backdropFilter: 'blur(4.5px)',
            background:
              'radial-gradient(232.36% 214.3% at 124.11% -92.78%, rgba(218, 227, 242, 0.20) 0%, rgba(53, 84, 137, 0.00) 100%), rgba(37, 45, 64, 0.70)',
            border: '1px solid #323232',
            borderRadius: '8px',
            boxShadow: '0px 0px 20px 0px rgba(66, 75, 108, 0.60)',
            color: '#e5e7eb',
            fontFamily: baiJamjuree.style.fontFamily,
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
          },
          success: {
            duration: 6000,
          },
        }}
      >
        {t => (
          <ToastBar toast={t}>
            {({ icon, message }) => {
              const T = TOAST_ICON_MAP[t.type]
              return (
                <>
                  {T ?? icon}
                  {message}
                  {t.type !== 'loading' && (
                    <button
                      onClick={() => {
                        toast.dismiss(t.id)
                      }}
                    >
                      <VscClose className="text-gray-200" />
                    </button>
                  )}
                </>
              )
            }}
          </ToastBar>
        )}
      </Toaster>
    </PostHogProvider>
  )
}
