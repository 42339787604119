import { useMemo } from 'react'

import { formatNumber, NumberType } from '..'

interface YieldTooltipProps {
  yieldData: { value?: number; lastUpdated: string }
  baseTooltip?: string
}

export const useFormatYieldTooltip = ({ baseTooltip = '', yieldData }: YieldTooltipProps) => {
  return useMemo(() => {
    const yieldStr = yieldData.value
      ? formatNumber(Math.round(yieldData.value), NumberType.NoDecimals) + '%'
      : undefined

    const lastUpdated = yieldData.lastUpdated
    const tooltip = lastUpdated ? `${baseTooltip}\n\nLast Updated: ${lastUpdated}` : baseTooltip

    return [yieldStr, tooltip]
  }, [yieldData, baseTooltip])
}
