'use client'

import clsx from 'clsx'
import { ReactNode } from 'react'

export enum WarningBoxVariant {
  LIGHT = 'warning-box border-[#2a3344] text-white',
  DARK = 'bg-black bg-opacity-40 border-[#2E323B] text-neutrals-500',
  RED = 'warning-box-red border-[rgba(255,85,0,.25)] text-white',
}

interface WarningBoxProps {
  children: ReactNode
  classes?: string
  variant?: WarningBoxVariant
}

export const WarningBox = ({ children, classes, variant = WarningBoxVariant.LIGHT }: WarningBoxProps) => {
  return (
    <div
      className={clsx(
        classes,
        variant,
        'flex items-start gap-3 rounded-lg border px-4 py-3 text-xs sm:text-sm backdrop-blur-[4.5px]',
      )}
    >
      {children}
    </div>
  )
}
