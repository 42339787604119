import { formatNumber, formatTokenUnits, NumberType } from '@ethena/shared-ui'

import { format, parseISO } from 'date-fns'
import { useMemo } from 'react'
import useSWR from 'swr'

import { IS_GERMAN } from '@/constants'

export const useMintedUsde = () => {
  const { data } = useSWR<{ value?: string; updated_at?: string }>(IS_GERMAN ? `/api/mints` : null)

  return useMemo(() => {
    if (!IS_GERMAN) return { lastUpdated: '', mintedUsde: '' }

    if (!data) return { lastUpdated: undefined, mintedUsde: undefined }

    return {
      lastUpdated: data.updated_at ? format(parseISO(data.updated_at), 'd MMM yy') : '',
      mintedUsde: formatNumber(Number(formatTokenUnits(BigInt(data.value ?? 0), 'USDe')), NumberType.TokenBalance),
    }
  }, [data])
}
