'use client'

import Image from 'next/image'

import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'
import { RxCaretDown } from 'react-icons/rx'

interface NavMenuProps {
  buttonContentOrText: string | ReactNode
  classes?: string
  children?: React.ReactNode
  padding?: string
  textSize?: string
  itemsPosition?: string
  textColor?: string
  stats?: ReactNode
  isDropdown?: boolean
  isMobileDropdown?: boolean
  mobileMenuSize?: string
}

const MobileNavDropDownMenu = ({
  children,
  classes,
  padding,
  textColor,
  textSize,
}: Pick<NavMenuProps, 'children' | 'classes' | 'textColor' | 'textSize' | 'padding'>) => {
  return <div className={clsx('flex flex-col', classes, padding, textSize, textColor)}>{children}</div>
}

export default function NavMenu({
  buttonContentOrText,
  children,
  classes,
  isDropdown,
  isMobileDropdown,
  itemsPosition = 'right-0 mt-2 origin-top-right',
  mobileMenuSize,
  padding = 'py-3 px-4',
  stats,
  textColor = 'text-gray-200',
  textSize = 'text-sm',
}: NavMenuProps) {
  return isMobileDropdown ? (
    <MobileNavDropDownMenu classes={classes} padding={padding} textColor={textColor} textSize={textSize}>
      {children}
    </MobileNavDropDownMenu>
  ) : (
    <Menu as="div" className={clsx('relative inline-block text-left opacity-95', classes)}>
      <div>
        {
          <Menu.Button>
            {({ open }) => (
              <div
                className={clsx(
                  'inline-flex w-full items-center justify-center gap-1  rounded-sm bg-black bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative',
                  open ? 'bg-gray-700' : '',
                  padding,
                  textSize,
                  textColor,
                )}
              >
                {typeof buttonContentOrText === 'string' ? (
                  <>
                    {buttonContentOrText}
                    <RxCaretDown />
                  </>
                ) : open ? (
                  <span className="flex justify-center w-5">
                    <Image src="/shared/close-icon.svg" alt="Close" width={16} height={16} />
                  </span>
                ) : (
                  buttonContentOrText
                )}
              </div>
            )}
          </Menu.Button>
        }
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {isDropdown ? (
          <div className="bg-gradient-to-b from-cblue-400/50 via-cgray-900 to-cblue-400/0 p-[1px] rounded-md backdrop-filter-[4.5px] glow-box !absolute top-10">
            <div className="black-gradient rounded-md flex items-center relative z-10">
              <Menu.Items className={clsx('h-full outline-none', itemsPosition)}>{children}</Menu.Items>
            </div>
          </div>
        ) : (
          <div
            className={clsx(
              'absolute z-20 p-0 rounded-[25px] border border-[#273248] bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -right-[10px] top-[50px] sm:-right-[20px] sm:top-[70px]',
              mobileMenuSize,
            )}
          >
            <Menu.Items className={clsx('h-full focus:outline-none max-h-[85%] overflow-auto', itemsPosition)}>
              {children}
            </Menu.Items>
            {stats && <div className="flex flex-col gap-2 pb-3 px-4 absolute bottom-0 w-full">{stats}</div>}
          </div>
        )}
      </Transition>
    </Menu>
  )
}
