import { StatTooltip } from './StatTooltip'

interface YieldTooltipProps {
  yieldStr?: string
  tooltip?: string
}

export const YieldTooltip = ({ tooltip, yieldStr }: YieldTooltipProps) => {
  return <StatTooltip title={`sUSDe APY: ${yieldStr}`} description={tooltip} />
}
