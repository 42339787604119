'use client'

import { NavBar, Route } from '@ethena/shared-ui/src/components/NavBar/NavBar'
import { Socials } from '@ethena/shared-ui/src/components/Socials'
import { SocialsButton } from '@ethena/shared-ui/src/components/SocialsButton'
import { Stats } from '@ethena/shared-ui/src/components/Stats'
import { StatWithIcon } from '@ethena/shared-ui/src/components/StatWithIcon'
import { useScrollbarWidth } from '@ethena/shared-ui/src/hooks/useScrollbarWidth'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'
import { useState } from 'react'

import { IS_GERMAN } from '@/constants'
import { useMintedUsde } from '@/hooks'
import useUserStore from '@/store/user-store'

const NavBarRight = () => {
  const [isSocialsOpen, setIsSocialsOpen] = useState(false)

  const { isIdle } = useUserStore()
  const { mintedUsde } = useMintedUsde()
  return (
    <div className="sm:flex hidden">
      <div
        className={clsx('çtransition-all ease-in duration-150', {
          'opacity-0 translate-x-1/2': isSocialsOpen,
          'opacity-100': !isSocialsOpen,
        })}
      >
        {!IS_GERMAN ? (
          <Stats isIdle={isIdle} />
        ) : (
          <StatWithIcon
            label="Minted"
            value={mintedUsde}
            tooltip={`Gross USDe minted since June 28, 2024`}
            imgSrc="/shared/usde.svg"
            imgClasses="flex"
          />
        )}
      </div>

      <SocialsButton isOpen={isSocialsOpen} setIsOpen={setIsSocialsOpen} />
    </div>
  )
}

const NavBarBottomMobile = () => {
  const { isIdle } = useUserStore()

  return (
    <div className="mt-4 flex flex-col gap-2">
      <Socials className="sm:hidden justify-between gap-3.5 flex-wrap w-fit pb-4" />
      {!IS_GERMAN && <Stats isIdle={isIdle} isMobileMenuStats />}
    </div>
  )
}

export function LandingWrapper({ children, routes }: { children: React.ReactNode; routes: Route[] }) {
  const scrollbarWidth = useScrollbarWidth()
  const pathname = usePathname()
  const isRouteMatch = routes.some(({ route }) => route === pathname)

  return (
    <div>
      <div
        className={clsx(
          'flex min-h-[calc(100vh-20px)] sm:min-h-[calc(100vh-40px)] flex-col rounded-4xl mx-2.5 sm:mx-[30px] my-2.5 sm:my-5 z-20 top-0 left-0 right-0 bottom-0 relative',
          `w-[calc(100vw - ${scrollbarWidth}px] max-w-[2000px] 4xl:mx-auto`,
          isRouteMatch ? 'layout-background-image border-2 border-cblue-600' : '',
        )}
      >
        <NavBar
          margin="mx-4 mt-6 hbase:mt-8 mb-2 sm:mx-6"
          routes={routes}
          collapseBreakpoint="xl:hidden"
          right={<NavBarRight />}
          bottomMobile={<NavBarBottomMobile />}
          mobileMenuSize="w-[calc(100vw-60px)] sm:w-[calc(100vw-110px)] md:w-[calc(100vw-110px)] h-[calc(100vh-130px)] sm:h-[calc(100vh-180px)]"
        />
        {children}
      </div>
    </div>
  )
}
