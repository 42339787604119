'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { Menu } from '@headlessui/react'
import clsx from 'clsx'
import { FC } from 'react'
import { RxChevronRight } from 'react-icons/rx'

export const DropDownNavItem: FC<{
  title: string
  route: string
  first?: boolean
  last?: boolean
  className?: string
  isDropdown?: boolean
}> = ({ className, first, isDropdown = false, last, route, title }) => {
  const path = usePathname()
  const isActive = path === route

  return (
    <Link href={route} target={route.startsWith('/') ? undefined : '_blank'}>
      <Menu.Item>
        <button
          className={clsx(
            'relative group flex w-full items-center whitespace-nowrap py-3 px-4 text-sm',
            isActive ? 'text-cblue-400 selected-nav-item-mobile' : 'text-gray-200',
            first ? 'rounded-t-lg' : '',
            last ? 'rounded-b-lg' : '',
            isDropdown && !isActive ? 'transition-all hover:translate-x-2' : '',
            className,
          )}
        >
          {!isActive && isDropdown && (
            <RxChevronRight className="absolute -left-1 hidden group-hover:flex h-5 w-5 text-gray-200" />
          )}
          {title}
        </button>
      </Menu.Item>
    </Link>
  )
}
