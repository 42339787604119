import { create } from 'zustand'

interface UserState {
  isIdle?: boolean
  setIsIdle: (isIdle: boolean) => void
  hasVisitedHomepage: boolean
  setHasVisitedHomepage: (hasVisitedHomepage: boolean) => void
}

const useUserStore = create<UserState>(set => ({
  hasVisitedHomepage: false,
  isIdle: false,
  setHasVisitedHomepage: (hasVisitedHomepage: boolean) => set({ hasVisitedHomepage }),
  setIsIdle: (isIdle: boolean) => set({ isIdle }),
}))

export default useUserStore
