'use client'

import Image from 'next/image'
import Link from 'next/link'

import clsx from 'clsx'
import { FC } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'

import { DropDownNavItem } from './DropDownNavItem'
import { NavItem } from './NavItem'
import NavMenu from './NavMenu'
import { NavStyleBox } from './NavStyleBox'

export interface Route {
  title: string
  route: string
  isActiveOverride?: boolean
  cutToMenuBreakpoint: 'md:block' | 'lg:block' | 'xl:block'
  routes?: Route[]
}

interface NavBarProps {
  routes: Route[]
  collapseBreakpoint?: 'md:hidden' | 'lg:hidden' | 'xl:hidden'
  margin?: string
  width?: string
  isAnimated?: boolean
  mobileMenuSize?: string
  right?: React.ReactNode
  bottomMobile?: React.ReactNode
}

export const NavBar: FC<NavBarProps> = ({
  bottomMobile,
  collapseBreakpoint = 'md:hidden',
  isAnimated,
  margin = 'mx-4 mt-6 mb-2 sm:mx-8 sm:mt-8',
  mobileMenuSize,
  right,
  routes,
  width,
}) => {
  return (
    <NavStyleBox
      variant="nav"
      className={clsx(margin, width, isAnimated && 'animate-in slide-in-from-top-6 duration-500')}
    >
      <Link href="/" className="flex h-full items-center">
        <Image
          src="/shared/ethena.svg"
          alt="Ethena Logo"
          priority
          width={0}
          height={0}
          style={{ height: '50%', width: 'auto' }}
          className="mr-2"
        />
        <span className={clsx('mr-2 text-xl sm:text-2xl sm:mr-8 lg:mr-9 xl:mr-6 2xl:mr-9')}>Ethena</span>
      </Link>
      {routes.map(({ cutToMenuBreakpoint, isActiveOverride, route, routes, title }) =>
        routes ? (
          <NavMenu
            key={title}
            buttonContentOrText={title}
            classes={clsx(`flex`, {
              [`hidden ${cutToMenuBreakpoint}`]: !!cutToMenuBreakpoint,
            })}
            padding="p-2"
            textSize="text-sm"
            itemsPosition="my-2 origin-center"
            mobileMenuSize={
              'sm:h-fit h-fit w-auto sm:right-0 px-0 py-0 !bg-black rounded border !border-[#273248] flex-col justify-center items-start gap-5 inline-flex'
            }
            isDropdown
          >
            {routes.map(({ route, title }, i) => (
              <DropDownNavItem
                title={title}
                route={route}
                key={title}
                first={i === 0}
                last={i === routes.length - 1}
                isDropdown
              />
            ))}
          </NavMenu>
        ) : (
          <NavItem
            title={title}
            route={route}
            key={title}
            isActiveOverride={isActiveOverride}
            classes={cutToMenuBreakpoint ? `hidden ${cutToMenuBreakpoint}` : undefined}
            padding="py-6 lg:px-2.5 2xl:px-4"
          />
        ),
      )}
      <div className="ml-auto flex items-center">{right}</div>
      {!!routes.length && (
        <NavMenu
          buttonContentOrText={<RxHamburgerMenu className="w-5 text-xl" />}
          classes={`ml-2 block ${collapseBreakpoint}`}
          padding="p-2"
          stats={bottomMobile}
          mobileMenuSize={mobileMenuSize}
        >
          {routes.map(({ route, routes: internalRoutes, title }, i) =>
            internalRoutes ? (
              <NavMenu key={title} buttonContentOrText={title} textSize="text-sm" padding="px-0" isMobileDropdown>
                {internalRoutes.map(({ route, title }, i) => (
                  <DropDownNavItem
                    title={title}
                    route={route}
                    key={title}
                    first={i === 0}
                    last={i === routes.length - 1}
                  />
                ))}
              </NavMenu>
            ) : (
              <DropDownNavItem title={title} route={route} key={title} first={i === 0} last={i === routes.length - 1} />
            ),
          )}
        </NavMenu>
      )}
    </NavStyleBox>
  )
}
