'use client'

import clsx from 'clsx'
import { FC } from 'react'

export type ButtonVariant = 'primary' | 'secondary' | 'error'

export interface FillButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  text: string
  fontSize?: string
  variant?: ButtonVariant
  classes?: string
  buttonWidth?: string
  rounding?: string
  fontWeight?: string
  padding?: string
}

const variantStyles = {
  error: 'bg-errorRed text-gray-200 disabled:bg-slate-600',
  primary: 'bg-gray-200 disabled:bg-white disabled:bg-opacity-30',
  secondary: 'text-cblue-500 disabled:text-gray-200',
}

export const FillButton: FC<FillButtonProps> = ({
  buttonWidth = 'w-full',
  classes = '',
  fontSize = 'text-sm',
  fontWeight = 'font-medium',
  padding = 'p-2',
  rounding = 'rounded-lg',
  text,
  variant = 'primary',
  ...otherProps
}) => {
  return (
    <button
      className={clsx(
        variantStyles[variant],
        fontSize,
        fontWeight,
        'overflow-hidden overflow-ellipsis whitespace-nowrap text-black transition ease-in-out enabled:hover:scale-[1.02] disabled:cursor-not-allowed',
        buttonWidth,
        padding,
        rounding,
        classes,
      )}
      type="button"
      {...otherProps}
    >
      {text}
    </button>
  )
}
