'use client'

import { throttle } from 'lodash-es'
import { useEffect, useState } from 'react'

const getScrollbarWidth = () => {
  return document.body.scrollHeight - window.innerHeight
}

export const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0)

  useEffect(() => {
    setScrollbarWidth(getScrollbarWidth())

    // Optional: Add a resize event listener if you want to check dynamically
    const handleResize = () => {
      setScrollbarWidth(getScrollbarWidth())
    }

    window.addEventListener('resize', throttle(handleResize, 100))

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return scrollbarWidth
}
