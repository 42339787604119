'use client'

import clsx from 'clsx'
import { FC } from 'react'
import { BsArrowUpRight } from 'react-icons/bs'
import { RxExternalLink } from 'react-icons/rx'

export const StyledALink: FC<{
  text: string
  url: string
  classes?: string
  hideUnderline?: boolean
  altIcon?: boolean
}> = ({ altIcon, classes = 'text-black border-black', hideUnderline, text, url }) => {
  return (
    <a
      className={clsx('flex cursor-pointer items-center outline-none', !hideUnderline && 'border-b leading-4', classes)}
      key={text}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      {altIcon ? <RxExternalLink className="text-cblue-400 text-sm ml-0.5" /> : <BsArrowUpRight className="ml-1" />}
    </a>
  )
}
