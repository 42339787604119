import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useWatchBlockNumber } from 'wagmi'

import { IS_TESTING } from '../constants/is-testing'

interface PollRpcProps {
  interval?: number
  shouldPoll?: boolean
  queryKey: QueryKey | QueryKey[]
  isIdle?: boolean
}

export const usePollRpc = ({ interval = 2, isIdle, queryKey, shouldPoll = true }: PollRpcProps) => {
  const queryClient = useQueryClient()

  useWatchBlockNumber({
    chainId: 1,
    onBlockNumber: blockNumber => {
      if (!shouldPoll || !blockNumber || blockNumber % BigInt(interval) != BigInt(0) || (isIdle && !IS_TESTING)) {
        return
      }

      if (Array.isArray(queryKey)) {
        queryKey.forEach(key => {
          queryClient.invalidateQueries({ queryKey: [key] })
        })
      } else {
        queryClient.invalidateQueries({ queryKey: [queryKey] })
      }
    },
  })
}
