import { FetchError } from '../types'

export const handleErrorAndParseRes = async (res: Response) => {
  const data = await res.json()

  if (!res.ok && process.env.NEXT_PUBLIC_PLAYWRIGHT_ENABLED !== 'true') {
    const error: FetchError = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = data
    error.status = res.status
    throw error
  }

  return data
}
