'use client'

import { cva, VariantProps } from 'class-variance-authority'

export const navStyleBoxVariants = cva(`flex items-center border border-neutrals-700 bg-black bg-opacity-30`, {
  defaultVariants: {
    rounded: 'default',
    variant: 'default',
  },
  variants: {
    rounded: {
      default: 'rounded-full',
      large: 'rounded-4xl',
    },
    variant: {
      default: 'backdrop-blur-[4.5px]',
      nav: 'z-20 box-content backdrop-blur-[1.5px] h-12 sm:h-16 px-3 sm:px-5',
    },
  },
})

interface NavStyleBoxProps extends VariantProps<typeof navStyleBoxVariants> {
  children?: React.ReactNode
  className?: string
}

export const NavStyleBox = ({ children, className, rounded, variant }: NavStyleBoxProps) => {
  return <div className={navStyleBoxVariants({ className, rounded, variant })}>{children}</div>
}
